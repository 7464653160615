<template>
        <div>
          <d-modal v-if="showDeleteModal" @close="handleCloseDeleteModal">
                <d-modal-header>
                 {{ $t('priceList.areYouSure') }}
                </d-modal-header>
              <d-modal-body>
               {{ $t('priceList.refreshAutomatically') }}
              </d-modal-body>
              <d-modal-footer>
                  <d-button class="float-left" v-on:click="handleCloseDeleteModal()">{{$t('priceList.cancel')}}</d-button>
                  <d-button class="float-right" v-on:click="deletePeriod()"  theme="danger">{{$t('priceList.delete')}}</d-button>
              </d-modal-footer>
            </d-modal>
            
          
          <d-row v-if="!this.$route.query.period">
          <d-col lg="12" class="mb-2">
            <div class="statistics__info mb-3">
                <strong>Existing pricelist</strong><br/>
                Current pricelist
            </div>
          </d-col>
            <d-col lg="9">
            <table class="table rounded shadow-sm">
            <thead>
              <tr>
                <th scope="col">PriceList</th>
                <th scope="col">Start</th>
                <th scope="col">End</th>
                <th scope="col">Affected Services</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
             <template v-for="(period,index) in periods">
              <tr :key="index">
                  <td @click="redirectFunc(period)" scope="col" class="align-middle"> 
                    {{ period.name }}
                  </td>
                  <td @click="redirectFunc(period)" scope="col" class="align-middle">
                     <span v-if="index>0">{{ converDate(period.available_from) }}</span> 
                     <span v-else> - </span>
                  </td>
                  <td @click="redirectFunc(period)" scope="col" class="align-middle">
                    <span v-if="index>0">{{ converDate(period.available_to) }}</span>
                    <span v-else> - </span>
                  </td>
                  <td @click="redirectFunc(period)" class="align-middle">
                    <template v-for="(services,index) in validServices">
                      <div :key="index">
                        <template v-for="pricelist in services.pricelists">
                          <div :key="pricelist.id" v-if="pricelist.name== period.name && pricelist.rows.length > 0"> 
                            <!-- check if service has price list  -->
                            {{ convertServiceLocationTypeToString(services.parking_type_id)}}
                            <span v-if="services.covered">{{ $t("priceList.covered") }}</span> <span v-else>{{ $t("priceList.uncovered") }}</span>
                          </div>
                        </template>
                      </div>
                    </template>
                  </td>
                  <td class="align-middle">
                    <div class="d-flex items-center align-items-center">
                      <button v-if="index > 0" class="period__delete-button float-right bg-transparent pb-1" v-on:click="handleClickDeleteModal(period)"><IconDelete  /> </button>
                      <span v-else class="ml-2"> <IconInfo/></span>
                  </div>
                  </td>
                </tr>
             </template>
 
             
            </tbody>
            </table>
          </d-col>
          <d-col lg="3">
            <div class="bg-white rounded shadow-sm mb-2 p-2" >
      <d-row class="mt-0 mb-3" >
      <d-col>
        <Feedback :bus="bus"/>
      </d-col>
    </d-row>   
            <button  @click="addNewPrioed=true; nonbookable=false" class="font-weight-bold btn btn-primary mb-2 p-2 mr-1">  {{ $t("priceList.new") }} {{ $t("priceList.PriceLists") }}</button>
            <button  @click="addNewPrioed=true; nonbookable=true" class="font-weight-bold btn btn-danger mb-2 p-2">{{ $t("priceList.addBlockingPeriod") }}</button>
            <div v-if="addNewPrioed">
              <d-row class="mb-3">
                  <d-row class="p-3 p-1">
                    <d-col lg="12">
                      <d-input class="mb-1" required v-model="periodName" :placeholder="$t('priceList.periodName')" />
                    </d-col>
                    <d-col lg="12">
                     <input  
                      class="form-control mb-1"
                      type="date"
                      placeholder="From"
                      v-model="newPeriodFrom"
                      @change="updatedPeriodTo"
                      >
        
                    </d-col>
                    <d-col lg="12">
                      <input  
                        class="form-control"
                        type="date"
                        placeholder="From"
                        format="dd MMM yyyy"
                        v-model="newPeriodTo"
                      >
  
                    </d-col>
                    <d-col lg="12" class="d-flex">
                      <div v-if="nonbookable"> {{ $t("priceList.closed") }} </div>
                    </d-col>
                    <d-col lg="2" class="d-flex">
                      <d-button class="pr-4 pl-4 pt-2 pb-2 mt-2"  theme="success" @click="addPeriod()">{{ $t('priceList.add') }}</d-button>
                      <d-button class="pr-4 pl-4 pt-2 pb-2 mt-2 ml-2"  theme="light" @click="addNewPrioed=false">{{ $t('priceList.close') }}</d-button>
                    </d-col>
                  </d-row>
                </d-row>
            </div>
          </div>
          </d-col>
          </d-row>
 
          <d-row v-else lg="12" >
                <d-col class="col-lg-9">    
                  <div class="d-flex justify-content-between align-items-center mb-4">
                    <div v-if="currentPeriod.name=='Default'">
                      <h4 class="mb-0 pb-0">{{ $t('priceList.default') }}</h4> 
                      <small>
                        <p class="m-0"> {{ $t("priceList.defaultPriceList1") }} </p>
                        <p class="m-0"> {{ $t("priceList.defaultPriceList2") }} </p>
                        <p class="m-0"> {{ $t("priceList.defaultPriceList3") }} </p>
                      </small>
                    </div>
                    <div v-else>
                      <h4 class="mb-0 pb-0">
                        {{ currentPeriod.name }}
                      </h4>
                    </div> 
                    <router-link class="btn btn-primary btn-sm mr-2 font-weight-bold d-flex align-items-center pr-4 pl-4" :to="{ name: ('servicePriceList') }" >
                      <i class="fa fa-arrow-left mr-2"></i> {{ $t("feedback.go_back") }}
                    </router-link>
            
            </div>
            <div v-if="newObject.every(item => !item.active) && !currentPeriod.nonbookable" class="text-danger pb-2"> 
              {{ $t("priceList.serviceAutosaved") }}
            </div>
            
            <d-row lg="12"  :class="[currentPeriod.nonbookable > 0 ? 'd-block bg-white rounded shadow-sm p-3' : 'p-3 bg-white rounded shadow-sm flex-nowrap overflow-auto']">
                <div v-if="currentPeriod.nonbookable">
                  <d-col class="col-lg-12">    
                    <h5 class="text-danger"> {{ $t("priceList.periodFull") }} </h5>
                    <p class="m-0"> {{ $t("priceList.periodFullDesc1") }} </p>
                    <p> {{ $t("priceList.periodFullDesc2") }} {{ $t("priceList.periodFullDesc3") }} </p>
                  </d-col>
                </div>
              <template v-for="(services,index) in this.validServices">
                <PriceList :currency="currentPeriod.currency" :key="currentPeriod.id + services.id"  :service="services" :nonbookable="currentPeriod.nonbookable" :zeroError="zeroError"  :serviceData="newObject[index]" />
              </template>
            </d-row>  
       
          <td>
        </td>
            <div v-if="serviceLocations.length === 0" class="provider-page__no-content">
              Has no Service Location yet
            </div>   
        </d-col>
    <d-col class="col-lg-3 rounded pb-4">
      <div class="bg-white rounded shadow-sm mb-2 p-2" >
      <d-row class="mt-0 mb-3" >
      <d-col>
        <Feedback :bus="bus"/>
      </d-col>
    </d-row>   
      <div class="d-flex justify-content-between align-items-center">
        <h5 class="m-0">{{ $t("priceList.PriceLists") }}</h5>
        <button type="button" class="btn btn-success ml-1 p-2" @click="saveFunc">
            <div v-if="this.updateLoading" style="height: 1rem; width: 1rem;" class="spinner-border text-light" role="status"></div>
            <span v-else>
              {{ $t("priceList.savePrices") }}
            </span>
        </button>
      </div>
            <hr>
        <button  @click="addNewPrioed=true; nonbookable=false" class="font-weight-bold btn btn-primary mb-2 p-2 mr-1">  {{ $t("priceList.new") }} {{ $t("priceList.PriceLists") }}</button>
        <button  @click="addNewPrioed=true; nonbookable=true" class="font-weight-bold btn btn-danger mb-2 p-2">{{ $t("priceList.addBlockingPeriod") }}</button>
        <div v-if="addNewPrioed">
          <d-row class="mb-3">
              <d-row class="p-3 p-1">
                <d-col lg="12">
                  <d-input class="mb-1" required v-model="periodName" :placeholder="$t('priceList.periodName')" />
                </d-col>
                <d-col lg="12">
                  <input  
                      class="form-control mb-1"
                      type="date"
                  
                      placeholder="From"
                      v-model="newPeriodFrom"
                      @change="updatedPeriodTo"
                      >
                </d-col>
                <d-col lg="12">
                  <input  
                        class="form-control"
                        type="date"
                        placeholder="From"
                     
                        format="dd MMM yyyy"
                        v-model="newPeriodTo"
                      >
                </d-col>
                <d-col lg="12" class="d-flex">
                  <div v-if="nonbookable"> {{ $t("priceList.closed") }} </div>
                </d-col>
                <d-col lg="2" class="d-flex">
                  <d-button class="pr-4 pl-4 pt-2 pb-2 mt-2"  theme="success" @click="addPeriod()">{{ $t('priceList.add') }}</d-button>
                  <d-button class="pr-4 pl-4 pt-2 pb-2 mt-2 ml-2"  theme="light" @click="addNewPrioed=false">{{ $t('priceList.close') }}</d-button>
                </d-col>
              </d-row>
            </d-row>
        </div>
      </div>
      <div class="bg-white shadow-sm mb-2 p-2 rounded" >
        <hr>
        
        <ul class="nav nav-tabs" id="myTab" role="tablist">
          <div v-if="updatePricelist">
            <d-row class="mb-3" >
              <d-row class="p-3 p-1">
                <d-col lg="12">
                  <d-input class="mb-1" required v-model="updatePricelistName" :placeholder="$t('priceList.periodName')" />
                </d-col>
                <d-col lg="12">
                  <input  
                    class="form-control mb-1"
                    type="date"
                    placeholder="From"
                   
                    format="dd MMM yyyy"
                    v-model="updatePricelistFrom"
                  >
                </d-col>
                <d-col lg="12">
              
                  <input  
                    class="form-control"
                    type="date"
                    placeholder="To"
                  
                    format="dd MMM yyyy"
                    v-model="updatePricelistTo"
                  >
                  
                </d-col>
          
                <d-col lg="12">
                  <div class="d-flex mt-2">
                    <d-button class="p-2 mr-2"  theme="success" @click="editPricelist()">{{ $t("priceList.saveDates") }}</d-button>
                    <d-button class="p-2"  theme="light" @click="updatePricelist = false">{{ $t("priceList.close") }}</d-button>
                  </div>
                </d-col>
              </d-row>
            </d-row>
        </div>
          
        <template v-for="(period,index)  in periods">
          <router-link :key="index" :to="{name: 'servicePriceList', query: {...$route.query, period: period.name }}" class="w-100 text-decoration-none">
            <li  class="nav-item w-100 rounded p-2" style="min-height: 4rem;"  :class="[ period.id ==currentPeriod.id && 'bg-light font-weight-bold text-white']">
              <div class="d-flex align-items-center justify-content-between">
              <a @click="setCurrentPeriod(period);checkDataExist()" class="text-dark pl-0 d-flex align-items-center w-100"  href="#" data-toggle="tab" role="tab" aria-selected="true">
                <div  class="mr-auto d-flex p-2">
                  <span v-if="period.name=='Default'">{{ $t('priceList.default') }}</span>  <span v-else>
                    {{ period.name }}
                  </span> 
                  <span v-if="period.name=='Default'" class="ml-2"> <IconInfo/></span>
                  <span v-if="period.nonbookable" class="ml-2">
                      <IconClosed :name="period.name" />
                  </span>  
                </div>
              </a>
              <div class="d-flex">
                  <button v-if="index > 0" class="period__delete-button float-right bg-transparent" v-on:click="handleClickDeleteModal(period)"><IconDelete  /> </button>
                  <button v-if="period.name!=='Default'" class="btn btn-info p-1 mt-1" @click="editHandler(period,index)">{{ $t('priceList.edit') }}</button>
              </div>
            </div>
              <span class="d-flex text-muted">
                  <small @click="setCurrentPeriod(period);checkDataExist()"  style="cursor: pointer;" v-if="period.name!=='Default'"> 
                    {{ converDate(period.available_from) }} To {{ converDate(period.available_to) }}
               </small>
              </span>
            </li>

            <d-modal  v-if="showDeleteModal" @close="handleCloseDeleteModal">
                <d-modal-header>
                 {{ $t('priceList.areYouSure') }}
                </d-modal-header>
              <d-modal-body>
               {{ $t('priceList.refreshAutomatically') }}
              </d-modal-body>
              <d-modal-footer>
                  <d-button class="float-left" v-on:click="handleCloseDeleteModal()">{{$t('priceList.cancel')}}</d-button>
                  <d-button class="float-right" v-on:click="deletePeriod()"  theme="danger">{{$t('priceList.delete')}}</d-button>
              </d-modal-footer>
            </d-modal>
          </router-link >
        </template>

      </ul>
      </div>
      </d-col>
  </d-row>
  </div>

</template>

<script>
import {mapProviderStateFields, } from "~/store";
import graphqlCalls from "~/graphql";
import mixins from "~/mixins";
import moment from 'moment';
import Feedback from "../components/Feedback.vue";
import PriceList from "@/components/PriceList";
import IconClosed from "../icons/IconClosed"
import IconInfo from "../icons/IconInfo"
import IconDelete from "../icons/IconDelete"

import _ from "lodash"
import Vue from "vue";

  
  export default {
		name: "ServicePricelist",
    components: {PriceList,Feedback,IconClosed,IconDelete,IconInfo},
    mixins: [graphqlCalls, mixins],
    
data(){
  let periodName = "" ;
        let newPeriodFrom = moment().format('YYYY-MM-DD');
        let newPeriodTo = moment(newPeriodFrom).add(1, 'd').format('YYYY-MM-DD');
        let errorMessage =''
        
  return {
    bus: new Vue(),
    serviceLocations :[],
    currentPeriod:{},
    validServices: [],
    periods: [],
    priodsData: [],
    pricePerServices: [],
    serviceData: [],
    newObject: [],
    addNewPrioed: false,
    periodName : "",
    newPeriodFrom,
    newPeriodTo,
    showDeleteModal: false,
    deletePeriodValue:{},
    nonbookable:false,
    updateLoading: false,
    zeroError:false,
    updatePricelist: false,
    updatePricelistName:"",
    updatePricelistFrom:"",
    updatePricelistTo:"",
    updatePeriodIndex:'',
  }
},
  computed: {
    ...mapProviderStateFields([
      'active_provider_index',
      'providers',
    ]),

    provider : function () {
      if(this.providers.length === 0) return null

      return this.providers[this.active_provider_index];

    },
  },
  mounted() {


  if(this.provider) {
    
    this.loadServiceLocations(this.provider.id)
  }


   },
  watch: {
    provider: function (val) {

      if(val) {
        this.loadServiceLocations(val.id);

      }

    },

  },

    methods: {
      updatedPeriodTo: function(){
        this.newPeriodTo = moment(this.newPeriodFrom).add(1, 'd').format('YYYY-MM-DD')
      },

      redirectFunc: function(period){
        this.setCurrentPeriod(period);
        this.checkDataExist();
        this.$router.push({
          path: '/servicePriceList',
          query: {
            period: period.name 
          },
        });
      
      },
      convertServiceLocationTypeToString: function (id) {
        let tempName
        
        if(id==="6dvgln4ofde"){
          tempName = this.$t("priceList.shuttle") 

        }
        if(id==="8ftpe6gpdvi"){
          tempName =  this.$t("priceList.valet") 

        }
        if(id==="hg49xy82wi"){
          tempName = "Park & Walk"

        }
        if(id==="lqbmkqreh"){
          tempName = "Park & Taxi"
        }
        return tempName
      },
      handleClickDeleteModal(period) {
        this.deletePeriodValue = period
        this.showDeleteModal = true
      },
      handleCloseDeleteModal() {
        this.showDeleteModal = false
      },
      converDate:  function(date){
       const result=  date.substr(0,10)
       const startDate = moment(result).format("DD/MM/YYYY")
       return startDate
      },
      editPricelist: async function (){
        
            this.errorMessage = ''
            this.updatePricelistName = this.validServices[0].pricelists.length !== 0 ? String(this.updatePricelistName) : "Default" 
            const tempFrom = this.validServices[0].pricelists.length !== 0 ? new Date(this.updatePricelistFrom).setUTCHours(0,0,0,0) : new Date("0001-01-01T00:00:00Z")
            const tempTo = this.validServices[0].pricelists.length !== 0 ? new Date(this.updatePricelistTo).setUTCHours(23,59,59,0) : new Date("0001-01-01T00:00:00Z")
 
          if(tempFrom > tempTo){
            this.bus.$emit('submitData', {type: 'warning', message: this.$t('priceList.startEndDate') })
          }
          const validatePriceListName = (priceListName) => {
              const nameRegex =/^[a-zA-Z0-9\s')(]+$/;
              return nameRegex.test(priceListName);
            };
            if(!validatePriceListName(this.updatePricelistName)){
              this.bus.$emit('submitData', {type: 'warning', message: 'Invalid characters in the price list name' })
              return
            }
            if(this.updatePricelistName === ''){ 
              this.bus.$emit('submitData', {type: 'warning', message: this.$t('priceList.periodShouldName') })
            }
            if(this.newPeriodFrom === undefined){ 
              this.bus.$emit('submitData', {type: 'warning', message: this.$t('priceList.periodStartingDate') })
            }
            if(this.newPeriodTo === undefined){ 
              this.bus.$emit('submitData', {type: 'warning', message: this.$t('priceList.periodEndingDate') })
            }
          let service = []
          if(this.updatePricelistName && this.newPeriodFrom && this.newPeriodTo  && tempTo> tempFrom){
          this.newObject.map(services=>{

          
          service.push({
            id:services.serviceId,
            rows: [],
            active: true
          })
      })
    
       const variables= {
          services: service,
          period:{
            name:this.currentPeriod.name,
            changedName : this.updatePricelistName,
            start: new Date(tempFrom).toISOString(),
            end: new Date(tempTo).toISOString(),
            nonbookable: this.currentPeriod.nonbookable,
            currency: this.providerCurrency
          }
        }


     

        try {
        var mutation = require("../graphql/pricelist/updatePricelistByPeriodName.gql")

      await this.$apollo.mutate({mutation, variables, fetchPolicy: 'no-cache'})
          .then(data => {
          if(data){
                this.setCurrentPeriod(this.validServices[0].pricelists[0])
                this.checkDataExist()
                this.updatePricelist = false
                const period = data.data.updatePricelistByPeriodName.services.period;
                this.periods[this.updatePeriodIndex] = period
                this.bus.$emit('submitData', {type: 'success', message: this.$t('priceList.pricelistUpdate')});
           
              }
            })
              } catch (error) {
                console.log(error);
              }
        
       
      }},

      saveFunc: async function (){
        this.zeroError = false
       
          let service = []
           this.newObject.map(services=>{
           let rows = []
            services.priceValues.map((price,index)=>{
              
              if(price==0 && services.active==true && !this.currentPeriod.nonbookable){
                this.zeroError = true
                
              }

              let toValue =index+1
              let extraValue =0
              if((index+1) ==  services.priceValues.length-1){
                toValue = 9999
                extraValue = services.priceValues[services.priceValues.length-1]
              }
              if(index < services.priceValues.length -1){
                rows.push({
                    from:index+1,
                    to:toValue,
                    price: this.currentPeriod.nonbookable ? 1 : parseFloat(price),
                    extra: parseFloat(extraValue)
                })
              }
              
            })
            
            service.push({
              id:services.serviceId,
              rows: rows,
              active: this.currentPeriod.name=='Default' ? true : services.active
            })
        })
       const variables= {
          services: service,
          period:{
            name:this.currentPeriod.name,
            start: this.currentPeriod.available_from,
            end: this.currentPeriod.available_to,
            nonbookable: this.currentPeriod.nonbookable,
            currency: this.providerCurrency
          }
        }

    if(!this.zeroError){
        this.updateLoading = true

        try {
        var mutation = require("../graphql/pricelist/updatePricelistByPeriodName.gql")

      await this.$apollo.mutate({mutation, variables, fetchPolicy: 'no-cache'})
          .then(data => {
          if(data){
            this.loadServiceLocations(this.provider.id)
                  this.updateLoading = false
                  this.bus.$emit('submitData', {type: 'success', message: this.$t('priceList.pricelistUpdate')});

              }
                  })
              } catch (error) {
                console.log(error);
              }
        
        }else{
          this.bus.$emit('submitData', {type: 'danger', message: this.$t('priceList.cant0') })
        }
      },
      deletePeriod: async function(){
        
        this.updatePricelist = false
        let service = []
           this.newObject.map(services=>{
            service.push({
              id:services.serviceId,
              rows: [],
              active: false
            })
        })
       const variables= {
          services: service,
          period:{
            name:this.deletePeriodValue.name,
            start: this.deletePeriodValue.available_from,
            end: this.deletePeriodValue.available_to,
            nonbookable: false,
          }
        }

      try {
        var mutation = require("../graphql/pricelist/updatePricelistByPeriodName.gql")
      await this.$apollo.mutate({mutation, variables, fetchPolicy: 'no-cache'})
          .then(data => {
            if(data){
                this.showDeleteModal = false
                const result = data.data.updatePricelistByPeriodName.services.period
                this.periods= this.periods.filter(item=>item.name !== result.name)
               
                this.setCurrentPeriod(this.validServices[0].pricelists[0])
                this.checkDataExist()
                this.bus.$emit('submitData', {type: 'warning', message:  this.$t('priceList.pricelistDelete')});
                if(this.$route.query.period){
                  this.$router.push({
                  path: '/servicePriceList',
                  query: {
                    period: 'Default' 
                  },
                });
                }
             
            }
      
          })
      } catch (error) {
        // console.log(error);
      }
    },
      addPeriod: async function () {
      try{
        this.errorMessage = ''
        this.periodName = this.validServices[0].pricelists.length !== 0 ? String(this.periodName) : "Default" 
        let tempFrom = this.validServices[0].pricelists.length !== 0 ? new Date(this.newPeriodFrom).setUTCHours(0,0,0,0) : new Date("0001-01-01T00:00:00Z")
        let tempTo = this.validServices[0].pricelists.length !== 0 ? new Date(this.newPeriodTo).setUTCHours(23,59,59,0) : new Date("0001-01-01T00:00:00Z")
        const findPeriod = this.periods.find(item=>item.name==this.periodName)
        const validatePriceListName = (priceListName) => {
              const nameRegex =/^[a-zA-Z0-9\s')(]+$/;
              return nameRegex.test(priceListName);
            };
            if(!validatePriceListName(this.periodName)){
              this.bus.$emit('submitData', {type: 'warning', message: 'Invalid characters in the price list name' })
              return
        }

       if(tempFrom > tempTo){
        this.bus.$emit('submitData', {type: 'warning', message: this.$t('priceList.startEndDate') })
       }
        if(findPeriod){
          this.bus.$emit('submitData', {type: 'warning', message: this.$t('priceList.periodNameDuplicate') })
        }
        if(!validatePriceListName(this.periodName)){
              this.bus.$emit('submitData', {type: 'warning', message: 'Invalid characters in the price list name' })
              return
        }
        
        if(this.periodName === ''){ 
          this.bus.$emit('submitData', {type: 'warning', message: this.$t('priceList.periodShouldName')  })
        }
        if(this.newPeriodFrom === undefined){ 
          this.bus.$emit('submitData', {type: 'warning', message: this.$t('priceList.periodStartingDate')  })
        }
        if(this.newPeriodTo === undefined){ 
          this.bus.$emit('submitData', {type: 'warning', message: this.$t('priceList.periodEndingDate')  })
        }
        if(this.periodName && this.newPeriodFrom && this.newPeriodTo && !findPeriod && tempTo> tempFrom){
          // Add a new pricing row
          let service = []
           this.newObject.map(services=>{
            service.push({
              id:services.serviceId,
              rows: [],
              active: true
            })
        })
       const variables= {
          services: service,
          period:{
            name: String(this.periodName),
            start: new Date(tempFrom).toISOString(),
            end: new Date(tempTo).toISOString(),
            nonbookable: this.nonbookable,
            currency: this.providerCurrency
          }
        }

          var mutation = require("../graphql/pricelist/updatePricelistByPeriodName.gql")
         
        await this.$apollo.mutate({mutation, variables, fetchPolicy: 'no-cache'})
            .then(data => {
             
         
              try{
                this.addNewPrioed = false
                this.periodName = ""
                this.newPeriodFrom = moment().format('YYYY-MM-DD');
               this.newPeriodTo = moment(this.newPeriodFrom).add(1, 'd').format('YYYY-MM-DD');
                this.periods.push(data.data.updatePricelistByPeriodName.services.period)
                this.bus.$emit('submitData', {type: 'success', message: this.$t('priceList.newPricelist') })
                }catch(e){
                  console.log(e);
                }
            })

        }
      }catch(err){
        console.log(err);
      }
    },
    reloadPricing(){
      try{
        this.$emit('changedPricing')
      }catch(e){
        console.log(e);
      }
    },
      setCurrentPeriod: function (priod) {
      
        if(this.updatePricelist ){
          this.updatePricelist= false
        }
      this.currentPeriod= priod
    },
    editHandler: function(period,index){
      
      this.currentPeriod= period;
        this.updatePricelist=true;
        this.updatePricelistName=period.name; 
        this.updatePricelistFrom=moment(period.available_from).utc().format('YYYY-MM-DD');
        this.updatePricelistTo=moment(period.available_to).utc().format('YYYY-MM-DD');
        this.updatePeriodIndex=index;
       
      },
    checkDataExist: function(){
      let priodValues = []
      const priodCount = this.timeDifference(this.currentPeriod.available_from,this.currentPeriod.available_to) //it calculates the time between start and end of priod name and makes inputs based on that

      let indexValue
        if(priodCount >=31 || this.currentPeriod.name=='Default'){ // if the priods count is more than 31 days then it should be just 31 days and extra values are stored in extra field 
          indexValue = 31 
        }else{
          indexValue = priodCount +1 
        }

      for (let index = 0; index <= indexValue  ; index++) { //indexValue + 1 is for the extra field
  
        priodValues.push(0)         
      }
       this.newObject =[]

       this.validServices.map((valiseService,valiedIndex)=>{
        
        let clonePeriodValues =  [...priodValues]
       
        const pricing =  valiseService.pricelists.find(item=>item.name==this.currentPeriod.name)
        
       if(pricing){
       
        pricing.rows.map((item)=>{

         let clonePricing = [...pricing.rows]

          if(clonePricing.length > 1){ 
          
            clonePeriodValues[clonePeriodValues.length-1] =  pricing.rows[pricing.rows.length-1].extra;
          }
        
          const index = clonePricing.indexOf(item); 
          if (index !== -1) {
            clonePeriodValues[index] = item.price;
          }
        })


      }

      this.newObject.push({
        serviceId:valiseService.id,
        priceValues: clonePeriodValues,
        active: !clonePeriodValues.every(item => item === 0), //if all array values are 0 then it's not active
        priod:{
          period:this.currentPeriod
        }

      })

      })


      },
    timeDifference : function(startDate,endDate){  
    const start = moment(startDate).format("YYYY-MM-DD");
    const end = moment(endDate).format("YYYY-MM-DD");
    return moment(end).diff(moment(start),'days')

  },
    loadServiceLocations: function (providerId,) {
    
      this.fetchServiceLocations(providerId,this.date).then((response) => {
        this.priodsData = []
      for (let index = 1; index <= this.timeDifference( this.currentPeriod.available_from,  this.currentPeriod.available_to); index++){
          this.priodsData.push(0)
      }
         this.providerCurrency = response.data.provider.currency
         this.serviceLocations = response.data.provider.serviceLocations ?? []
         this.validServices = []
         this.periods = []
  
         this.serviceLocations.map((item)=>{
          if (item.services.length>0){ 
            item.services.map((singleService)=>{
         if(singleService.enabled){
           const data = {
             id:singleService.id,
             covered: singleService.covered,
             parking_type_id: singleService.parking_type_id,
             priodsData: this.priodsData,
             pricelists: singleService.pricelists,
             active: true

           }
             this.validServices.push(data)
         }
          
            

            })        
          }

         })
          
          let array   =[]
          this.validServices.map(pricelist=>{
            pricelist.pricelists.map(item=>{
              array.push(item)
            })
            
          })
      

            const uniqueAuthors = _.uniqWith(array, (arrVal, othVal) => arrVal.name === othVal.name)
           
            this.periods = uniqueAuthors;  
            if(this.$route.query.period){
              const findPeriod = this.periods.find(item=>item.name==this.$route.query.period)
             this.setCurrentPeriod(findPeriod)
             
          
            }else{
                this.setCurrentPeriod(this.periods[0])
            }
            this.validServices.map(item=>{
            this.checkDataExist(item.id)
         })
      })

    }
    }
	}

</script>



